<template>
  <div>
    <div v-for="(lang,idx) in languageList" :key="idx" class="d-flex align-items-center justify-content-center mb-1" style="gap: 2px">
      <div class="d-flex align-items-center">
        <feather-icon
            :icon="
            data.languageId == lang.languageId || doesDataIncludes(lang.languageId, data.otherLanguages)
              ? 'CheckIcon'
              : 'XIcon'
          "
            size="15"
            class="custom-b-margin"
            :class="
            data.languageId == lang.languageId || doesDataIncludes(lang.languageId, data.otherLanguages)
              ? 'text-success'
              : 'text-danger'
          "
        />
        <span style="font-size: 12px">{{ lang.name }}</span>
        <b-button
            :disabled="data.languageId != lang.languageId && !doesDataIncludes(lang.languageId, data.otherLanguages)"
            @click="decideAction(lang)"
            variant="primary"
            type="button"
            class="font-bold p-0 text-white bg-primary my-btn p-25"
            size="sm"
        >
          Select
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
export default {
  components: {
    BButton,
  },
  emits: ["renderDeleteModal", "getBlogCategoryCreateData"],
  props: {
    data: {
      type: Object,
      required: true,
    },
    languageList: {
      type: Array,
      required: true,
    },
    routerEditName: {
      type: String, // Edit Page of entity name in router
      required: false, //Only required if isBlogCategory props is false
    },
    isBlogCategory: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    deletePage(lang) {
      if (this.data.languageId == lang.languageId) {
        this.renderDeleteModal({id: this.data.id});
      } else if (this.doesDataIncludes(lang.languageId, this.data.otherLanguages)) {
        this.renderDeleteModal({
          id: this.findIdInOtherLanguages(lang.languageId, this.data.otherLanguages),
        });
      }
    },
    renderDeleteModal(obj) {
      this.$emit("renderDeleteModal", obj);
    },
    decideAction(lang) {
      if (this.data.languageId == lang.languageId) {
        this.redirectToEdit(this.data.id);
      } else if (this.doesDataIncludes(lang.languageId, this.data.otherLanguages)) {
        this.redirectToEdit(
            this.findIdInOtherLanguages(lang.languageId, this.data.otherLanguages)
        );
      }
    },
    doesDataIncludes(preRoute, data) {
      if (data) {
        let selected = data.find((item) => {
          if (item.languageId == preRoute) {
            return item;
          }
        });
        if (selected) {
          return true;
        }
      }
    },
    findIdInOtherLanguages(preRoute, data) {
      let selected = data.find((item) => {
        if (item.languageId == preRoute) {
          return item;
        }
      });
      if (selected) {
        return selected.objectId;
      }
    },
    redirectToEdit(id) {
      this.$router.push({
        name: this.routerEditName,
        params: {
          id,
        },
      });
    },
  }
}
</script>

<style>
.custom-b-margin {
  margin-bottom: 1px;
}
.my-btn {
  font-size: 13px;
  margin-left: 5px;
}
hr {
  margin: 0 !important;
  padding: 0 !important;
  margin: 3px 0 3px 0 !important;
  border-top: 0 !important;
}
</style>