<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="mb-3 w-100 d-flex align-items-center">
        <h1>Blogs SEO Setting</h1>
      </div>
      <b-row>
        <b-col cols="12">
          <b-table
            class="position-relative"
            :items="blogsList"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="Nothing found!"
          >
            <template #cell(cover)="data">
              <b-media vertical-align="center">
                <b-avatar
                    rounded
                    size="100"
                    :src="`${baseUrl}${data.item.imageUrl}`"
                    :variant="`success`"
                />
              </b-media>
            </template>
            <template #cell(actions)="data">
              <SelectLanguage
                  :data="generateObjectForAddEditRemoveButton(data.item)"
                  :languageList="languageList"
                  :routerEditName="'pages-seo-blogs-single'"
                  @renderDeleteModal="renderDeleteModal"
              ></SelectLanguage>
<!--              <div class="d-flex align-items-start" style="gap: 2rem">-->
<!--                <b-link-->
<!--                  :to="{-->
<!--                    name: 'pages-seo-blogs-single',-->
<!--                    params: { id: data.item.id },-->
<!--                  }"-->
<!--                >-->
<!--                  <feather-icon-->
<!--                    size="20"-->
<!--                    icon="SettingsIcon"-->
<!--                    class="text-primary"-->
<!--                  />-->
<!--                </b-link>-->
<!--              </div>-->
            </template>
          </b-table>
        </b-col>
        <b-col cols="12">
          <!-- pagination -->
          <div class="my-2 d-flex align-items-center justify-content-center">
            <b-pagination
              v-model="pageNumber"
              :total-rows="totalCount"
              :per-page="count"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import AddEditDeleteButton from "@/views/components/utils/AddEditDeleteButton.vue";

export default {
  title: "Blogs SEO Setting",
  mounted() {
    this.getAllBlogsList();
    this.getAllLanguages();
  },
  watch: {
    pageNumber: {
      handler(val) {
        this.getAllBlogsList();
      },
    },
  },
  data() {
    return {
      isLoading: false,
      languageList:[],
      baseUrl:Helper.baseUrl,
      count: 10,
      pageNumber: 1,
      totalCount: null,
      categoryName: null,
      tagId: null,
      searchByTitle: null,
      date: null,
      blogsList: null,
      blogStatuses: {
        1: "Published",
        2: "Archive",
      },
      myTableColumns: [
        {
          key: "cover",
          label: "Cover",
        },
        {
          key: "title",
          label: "Blog Title",
        },
        {
          key: "status",
          label: "Blog Status",
        },
        {
          key: "userInfo.fullName",
          label: "Author",
        },
        {
          key: "actions",
          label: "Set SEO Settings",
        },
      ],
      tempBlog: null,
    };
  },
  methods: {
    async getAllBlogsList() {
      try {
        this.isLoading = true;
        let _this = this;
        let getAllBlogs = new GetAllBlogs(_this);
        let qParams = {
          count: _this.count,
          pageNumber: _this.pageNumber,
        };
        if (this.categoryName) {
          qParams["categoryName"] = this.categoryName;
        }
        if (this.tagId) {
          qParams["tagId"] = this.tagId;
        }
        if (this.searchByTitle) {
          qParams["searchByTitle"] = this.searchByTitle;
        }
        if (this.date) {
          qParams["date"] = this.date;
        }
        getAllBlogs.setRequestParam(qParams);
        await getAllBlogs.fetch((response) => {
          if (response.isSuccess) {
            _this.blogsList = response.data.data;
            _this.totalCount = response.data.totalCount;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getAllLanguages() {
      try {
        let requestParams = {
          count: 100,
          pageNumber: 1,
        };
        if (this.searchCommand) {
          requestParams["searchCommand"] = this.searchCommand;
        }
        this.isLoading = true;
        let _this = this;
        let getAllLangs = new GetAllLangs(_this);

        getAllLangs.setRequestParam(requestParams);
        await getAllLangs.fetch((response) => {
          if (response.isSuccess) {
            _this.languageList = response.data.languages;
            // _this.totalCount = response.data.languagesCount;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    renderDeleteModal(blog) {
      this.tempBlog = JSON.parse(JSON.stringify(blog));
      this.$bvModal.show("delete-modal");
    },
    generateObjectForAddEditRemoveButton(blogDetails) {
      return {
        id: blogDetails.id,
        languageId: blogDetails.languageId,
        otherLanguages: blogDetails.otherLanguages || [],
        mainId: blogDetails.mainId,
      };
    },
  },
  components: {
    SelectLanguage,
    AddEditDeleteButton,
    BContainer,
    BCard,
    BRow,
    BFormRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BTable,
    BImg,
    BMedia,
    BAvatar,
    BModal,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
  },
};
import {
  BContainer,
  BCard,
  BRow,
  BFormRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BTable,
  BImg,
  BMedia,
  BAvatar,
  BModal,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
} from "bootstrap-vue";
import { GetAllBlogs } from "@/libs/Api/Blog";
import {GetAllLangs} from "@/libs/Api/Language";
import SelectLanguage from "@/views/components/utils/SelectLanguage.vue";
import Helper from "@/libs/Helper";
</script>
